<template>
  <div class="container">
    <div class="right"
         style="width: 100%">
      <div class="bgImg">
        <div style="
            width: 100%;
            padding-bottom: 0.26667rem;
            box-sizing: border-box;
            font-size: 0.27rem;
            position: absolute;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
          ">
          {{uaCompanySlogan}}
        </div>
        <div class="introduce">
          <div class="menus">
            <div class="produce_titleList">
              <!-- <div style="display: flex;ju"> -->
              <div :class="
                  item.htId == currentValue
                    ? 'produce_title title_border'
                    : 'produce_title'
                "
                   @click="changeTitle(item.htId, item.htName)"
                   v-for="(item, index) in titleList"
                   :key="index">
                {{ item.htName }}
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        class="honor"
        @dblclick="handleFullScreen"
       v-show="currentName == '领导关怀'"
      >
       <div class="honorList">
          <div
            class="honorList_item"
            v-for="(item, index) in honorList"
            :key="index"
           
          >
            <div class="honorList_item_img">
              <el-image
                :src="item.chUrl"
                alt=""
              
              ></el-image>
            </div>
            <div class="honorList_item_title">{{ item.chName }}</div>
          </div>
        </div> -->
      <!-- <div class="honorList" style="justify-content: flex-start">
          <div
         
            class="honorList_item2"
            v-for="(item, index) in honorList"
            :key="index"
          >
            <div class="honorList_item_img2">
              <el-image
                :src="item.chUrl"
                alt=""
             
              ></el-image>
            </div>
            <div class="honorList_item_title2">{{ item.chName }}</div>
            <div style="color:#CECECE;font-size:16px;">{{item.chCreateTime}}</div>
          </div>
        </div> -->
      <!-- </div> -->
      <!--  -->
      <div class="honor">
        <div class="honorList">
          <div :class="fullscreen ?'honorList_item118':'honorList_item100'"
               v-for="(item, index) in honorList"
               :key="index">
            <div :class="fullscreen ?'honorList_item_img118':'honorList_item_img100'">
              <el-image :src="item.chUrl"
                        alt=""
                        :preview-src-list="[item.chUrl]"></el-image>
            </div>
            <div class="honorList_item_title">{{ item.chName }}</div>
            <div v-show="currentName == '领导关怀'"
                 style="color:#CECECE;font-size:14px;">{{item.chCreateTime}}</div>
          </div>
          <span style="width:270px"></span>
          <span style="width:270px"></span>
          <span style="width:270px"></span>
          <span style="width:270px"></span>
          <span style="width:270px"></span>
        </div>
        <!-- <div style="  padding-bottom: 40px;color: #969799; font-size:15px;margin-top:20px">丹道提供技术支持</div> -->
        <Fotter></Fotter>
      </div>
    </div>
  </div>
</template>

<script>
import { getHonorType, getHonorList, getInfo } from "../../utils/api.js";
import Fotter from "../../components/fotter.vue";
export default {
  components:{
    Fotter,
  },
  data () {
    return {
      List: [
        {
          img: require("../../assets/image/icon/introduce_1.png"),
          title: "丹道创的业",
        },
        {
          img: require("../../assets/image/icon/introduce_2.png"),
          title: "丹道事业观",
        },
        {
          img: require("../../assets/image/icon/introduce_3.png"),
          title: "三三理念",
        },
        {
          img: require("../../assets/image/icon/introduce_4.png"),
          title: "丹道三心",
        },
      ],
      titleList: [],
      honorList: [],
      currentValue: 0,
      currentName: "",
      fullscreen: false,
      uaCompanySlogan: ''
    };
  },
  created () {
    this.getHonorType();
    this.getInfo()
    // this.uaCompanySlogan = localStorage.getItem('uaCompanySlogan')
  },
  methods: {
    getInfo () {
      getInfo({ uaId: 1 }).then((res) => {
        if (res.code == 20000) {
          console.log(res.data);
          this.uaCompanySlogan = res.data.uaCompanySlogan
        }
      })
    },
    getHonorList (htId) {
      getHonorList({ htId: htId, uaId: 1 }).then((res) => {
        if (res.code == 20000) {
          console.log(res);
          this.honorList = res.data;
        }
      });
    },
    getHonorType () {
      getHonorType({ uaId: 1 }).then((res) => {
        if (res.code == 20000) {
          console.log(res);
          this.titleList = res.data;
          this.currentValue = res.data[0].htId;

          this.getHonorList(this.currentValue);
        }
      });
    },
    //切换title
    changeTitle (e1, e2) {
      console.log(e1, e2);
      this.currentValue = e1;
      this.currentName = e2

      this.getHonorList(this.currentValue);
    },
  },
};
</script>

<style scoped >
.container {
  background: #fafafa;
  width: 100%;
  height: 100vh;
}

.right {
  width: 100%;
  min-height: 100vh;
  background: #fefefe;
  position: absolute;
  top: 0;
  right: 0;
}
.bgImg {
  width: 100%;
  height: 280px;
  background: url("https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/git.gif") no-repeat;
  background-size: 100% 250%;
  position: relative;
  margin-top: 5%;
}
.bgImg_title3 {
  font-size: 28px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  color: #fff;
  text-align: left;
}
.introduce {
  width: 100%;
  z-index: 100;
  background: #fefefe;
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
}
.menus {
  height: 60px;

  /* margin-top: -20px; */
  border-bottom: 1px solid #dedede;
}
.produce_List {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  justify-content: space-between;
}
.produce_title {
  font-size: 18px;
  font-weight: 550;
  margin-left: 20px;
  text-align: center;
  margin: 20px 50px 0px 50px;
  color: #333232;
  padding-bottom: 3px;
  box-sizing: border-box;
}
.title_border {
  border-bottom: 3px solid #59a8ee;
}
.produce_titleList {
  width: 100%;
  padding-right: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: scroll; */
  overflow-x: auto;
  /* display: -webkit-box; */
  -webkit-overflow-scrolling: touch;
}
.produce_titleList::-webkit-scrollbar {
  display: none;
}
.honor {
  /* height: 80vh; */
  /* overflow: scroll; */
  margin-top: 0px;
}
.honorList {
  /* width: 100%; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 35px;
  padding: 40px;
  padding-top: 40px;
  box-sizing: border-box;
  /* background: #fafafa; */
}
.honorList_item118 {
  width: 170px;
  height: 180px;
  padding: 14px;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-left: 8px;
  display: flex;
  justify-content: flex-start;

  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0 rgba(0, 0, 0, 0.5);
}
.honorList_item100 {
  width: 240px;
  height: 255px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin-left: 30px;
  display: flex;
  justify-content: flex-start;

  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0 rgba(0, 0, 0, 0.5);
}
.honorList_item_img118 {
  width: 140px;
  height: 120px;
  margin-bottom: 10px;
}
.honorList_item_img100 {
  width: 200px;
  height: 160px;
  margin-bottom: 10px;
}
.honorList_item_img118 .el-image {
  width: 200px;
  height: 160px;
}
.honorList_item_img100 .el-image {
  width: 200px;
  height: 160px;
}
.honorList_item_title {
  color: #4a4a4a;
  font-size: 15px;
  font-weight: 550;
}
.honorList_item_title2 {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 550;
}
.honorList_item2 {
  width: 225px;
  height: 225px;
  padding: 18px;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-right: 30px;
}
.honorList_item_img2 {
  width: 195px;
  height: 160px;
}
.honorList_item_img2 img {
  width: 195px;
  height: 130px;
  border-radius: 8px;
}
</style>